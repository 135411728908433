<template>
  <div class="introduce" :class="{ bgc: !isWhite }">
    <div class="content">
      <div class="img" v-show="isLeft">
        <img @click.self="showBigImage(img)" :src="img" alt="" />
      </div>
      <div class="text" v-show="text1">
        <strong>{{ title }}</strong>
        <p>{{ text1 }}</p>
        <p>{{ text2 }}</p>
      </div>
      <div class="img" v-show="!isLeft">
        <img :src="img" @click.self="showBigImage(img)" alt="" width="600px"  height="300px"/>
      </div>
      <BigImg
        :visible="photoVisible"
        :url="bigImgUrl"
        @closeClick="
          () => {
            photoVisible = false;
          }
        "
      ></BigImg>
    </div>
  </div>
</template>

<script>
import BigImg from "@/views/product/BigImg.vue";
export default {
  name: "Introduce",
  components: { BigImg },
  data() {
    return {
      photoVisible: false,
      bigImgUrl: "",
    };
  },
  props: {
    title: {
      type: String,
    },
    text1: {
      type: String,
    },
    text2: {
      type: String,
    },
    isLeft: {
      type: Boolean,
      default() {
        return true;
      },
    },
    isWhite: {
      type: Boolean,
      default() {
        return false;
      },
    },
    img: {
      type: String,
    },
  },
  methods: {
    showBigImage(imgUrl) {
      //点击图片函数，点击后，把photoVisible设置成true
      if (imgUrl != "") {
        this.photoVisible = true;
        this.bigImgUrl = imgUrl;
      }
    },
  },
};
</script>

<style scoped>
.bgc {
  background-color: #d6e1f7;
}
.introduce {
  display: flex;
  /* height: 549px; */
  /* width: 100%; */
  height: 400px;
  align-items: center;
}
.content {
  font-size: 14px;
  color: #666666;
  width: 1200px;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content img {
  /* height: 350px;
  width: 600px; */
  max-height: 350px;
  max-width: 600px;
}
strong {
  font-size: 25px;
  font-weight: bold;
}
.text {
  /* margin-right: 50px; */
  text-align: justify;
  margin: 20px;
  width: 300px;
}
</style>